<template>
  <div class="areaTree">
    <!-- 搜索框 -->
    <div :class="`areaTree__search ${background?'bj':''}`">
      <el-input placeholder="请输入区域名称" v-model="searchVal"></el-input>
      <i class="el-icon-search"></i>
    </div>
    <div class="areaTree__tree">
      <el-tree
        v-if="data.length"
        :data="data"
        node-key="id"
        ref="tree"
        :filter-node-method="filterNode"
        :props="defaultProps"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <p>
            <span class="fl" @click="handleNodeClick(data)">
              <!-- 变量 pathType 区分  -->
              <!-- 30文件夹 41圆形 42线性 43矩形 44多边形 -->
              <!-- <img v-if="getCity" class="HeaderCloseIcon" :src="cityIcon" /> -->
              <Iconfont
                v-if="getCity"
                class="iconClass"
                name="icon-jiankongzhongxin"
                @click.stop="$emit('close', menu)"
              ></Iconfont>
              <span v-else>
                <Iconfont
                  class="iconClass"
                  name="icon-fenzuwenjianjia"
                  v-if="data.pathType===30"
                ></Iconfont>
                <Iconfont
                  class="iconClass"
                  name="icon-yuanxingquyu"
                  v-if="data.pathType===41"
                ></Iconfont>
                <Iconfont
                  class="iconClass"
                  name="icon-xianxingquyu"
                  v-if="data.pathType===42"
                ></Iconfont>
                <Iconfont
                  class="iconClass"
                  name="icon-juxingquyu"
                  v-if="data.pathType===43"
                ></Iconfont>
                <Iconfont
                  class="iconClass"
                  name="icon-duobianxingquyu"
                  v-if="data.pathType===44"
                ></Iconfont>
              </span>
              {{ node.label }}
            </span>
            <span class="fr" v-if="isHover">
              <el-tooltip effect="dark" content="添加">
                <i
                  v-show="data.nodeType"
                  class="el-icon-circle-plus"
                  @click="handleOperate(0,data)"
                ></i>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除">
                <i class="el-icon-remove" @click="handleOperate(1,data)"></i>
              </el-tooltip>
              <el-tooltip effect="dark" content="编辑">
                <i class="el-icon-edit" @click="handleOperate(2,data)"></i>
              </el-tooltip>
            </span>
          </p>
        </div>
      </el-tree>
      <el-empty
        v-else
        :image="require('@/assets/images/electronicFence/null.png')"
        :image-size="120"
        :description="`当前无数据，请添加`"
      />
    </div>
  </div>
</template>
<script>
import { getTreeArea } from '@/api/getRule.js';
import { getAreaBaseInfo } from '@/api/getCity.js';
export default {
  //区域分类
  name: "AreaTree",
  props: {
    //是否分页
    showAll: {
      type: Boolean,
      default: false
    },
    //是否加载省市区
    getCity: {
      type: Boolean,
      default: false
    },
    //是否显示背景
    background: {
      type: Boolean,
      default: false
    },
    //是否显示按钮组
    isHover: {
      type: Boolean,
      default: false
    },
    filterData: { // 树形图数据
      type: Object,
      default() {
        return {};
      }
    },
    type: { // 查询区域类型
      type: Number,
      default: null
    }
  },
  computed: {
    cityIcon() {
      return require('@/assets/images/城市图标.png');
    }
  },
  data() {
    return {
      searchVal: '',
      iconArr: {
        30: 'icon-fenzuwenjianjia',
        41: 'icon-yuanxingquyu',
        42: 'icon-xianxingquyu',
        43: 'icon-juxingquyu',
        44: 'icon-duobianxingquyu'
      },
      list: [],
      data: [],
      defaultProps: {
        children: 'treePathInfo',
        label: 'pathName'
      }
    };
  },
  watch: {
    searchVal: {
      handler(val) {
        this.$refs.tree.filter(val);
      },
      deep: true
    }
  },
  created() {
    this.handleInit(this.data);
  },
  mounted() {
    this.initTreeData(); // 初始化区域树
  },
  methods: {
    searchArea() {
      this.initTreeData();
    },
    // 加载区域树数据
    async initTreeData() {
      let result;
      if (this.getCity) {
        this.defaultProps = {
          label: 'name',
          children: 'list'
        };
        result = await getAreaBaseInfo();
        if (result.flag !== 1) return this.$message.warning(result.msg);
        this.data = result.obj.list;
      }
      else {
        result = await getTreeArea({ type: this.type, flag: this.showAll ? 1 : 0, name: this.searchVal });
        if (result.flag !== 1) return this.$message.warning(result.msg);
        if (!this.filterData.pathId) return this.data = result.rows;

        const { pathId } = this.filterData;
        this.data = result.rows.filter(item => item.pathId !== pathId);
      }
    },

    //初始化数据
    handleInit(arr) {
      //循环获取全部树形图数据
      arr.map(item => {
        this.list.push({ value: item.label, label: item.label });
        item.hasOwnProperty('children') && this.handleInit(item.children);
      });
    },

    //tree筛选
    filterNode(value, data) {
      if (!value) return true;

      return data[this.defaultProps['label']].indexOf(value) !== -1;
    },

    // 功能点击事件
    handleOperate(type, data) {
      this.$emit('handleOperate', { type, data });
    },

    // 树节点点击事件
    handleNodeClick(node) {
      this.$emit('on-change', { node });
    },
  }
}
</script>
<style lang="scss" scoped>
.areaTree {
  height: 100%;
  width: 100%;
  &__search {
    box-sizing: border-box;
    border-bottom: 1px solid #f2f2f2;
    padding: 6px 10px;
    position: relative;
    background: #f2f2f2;
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 22px;
      color: #c2c2c2;
    }
    ::v-deep .el-input__inner {
      border: none;
      background: #f2f2f2;
    }
  }
  .bj {
    background: #f2f2f2;
  }
  &__tree {
    padding: 10px;
    box-sizing: border-box;
    height: calc(100% - 45px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      width: 6px;
      background-color: rgba(144, 147, 153);
      transition: background-color 0.3s;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(144, 147, 153);
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: rgba(144, 147, 153);
    }
    background: #fff;
    .area__icon {
      color: #ee761c;
      margin-right: 10px;
    }
    .grap__icon {
      color: #1890ff;
      margin-right: 10px;
    }
    i {
      font-size: 14px;
    }
    ::v-deep .el-tree-node__content {
      width: 100%;
      box-sizing: border-box;
      .custom-tree-node {
        width: 100%;
        font-size: 14px;
        text-align: left;
        > p {
          width: 100%;
          display: flex;
          justify-content: space-between;
          height: auto;
          margin: 0;
          .fl {
            display: inline-block;
            width: 100%;
            // width: 50%;
          }
          .fr {
            display: inline-block;
            // width: 50%;
            text-align: right;
            > i {
              opacity: 0;
              color: #1890ff;
              cursor: pointer;
              margin-right: 10px;
            }
          }
        }
        > p:hover {
          .fr {
            z-index: 100;
            > i {
              opacity: 1;
            }
          }
        }
      }
    }
    ::v-deep .el-tree-node__expand-icon.expanded {
      border-bottom: 1px solid #e6e6e6;
      border-left: none !important;
    }
    ::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
      border-left: 1px solid #e6e6e6;
    }
    ::v-deep .el-tree {
      > .el-tree-node > .el-tree-node__content > .el-tree-node__expand-icon {
        border: none !important;
      }
    }

    ::v-deep .el-tree-node__content:hover {
      background: #fef3dc;
      color: #1e1e1e;
    }
    ::v-deep .el-tree-node,
    ::v-deep .el-tree-node__children {
      background: #fff;
      color: #1e1e1e;
    }
    ::v-deep .el-tree-node:focus > .el-tree-node__content {
      background-color: transparent !important;
    }

    ::v-deep .is-current {
      background: #fef3dc !important;
      color: #1e1e1e;
    }
  }
}
.image-city {
  background: url(/images/city.png) 2px 3px no-repeat;
  width: 16px;
  height: 16px;
}
.iconClass {
  font-size: 16px;
}
</style>
