<template>
  <el-dialog v-if="modal"
    :visible="modal"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    @close="onModalChange"
    width="350px">
    <template #title>
      <div>{{title}}</div>
    </template>
    <div class="addClass">
      <div class="addClass__content">
        <AreaTree background
          :type="30"
          :filterData="iProp"
          ref="areaTreeRefs"
          @on-change="nodeClick" />
      </div>
      <el-row class="addClass__row">
        <el-col class="center"
          :span="6">{{title == '增加分类'?'分类名称':'区域名称'}}：</el-col>
        <el-col :span="18">
          <el-input type="text"
            v-model="conditionObjs.pathName"
            ref="inputData" />
        </el-col>
        <el-col :span="24"
          v-if="!nodeType">
          <div class="draw-pane">
            <el-button-group style="width:100%"
              v-if="type !== null">
              <el-button v-for="item in tabs"
                :key="item.type"
                :class=" type === item.type ? 'active' : ''"
                @click="drawTypeClick(item)">{{item.label}}</el-button>
            </el-button-group>
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <div>
        <span class="footer-span"
          v-if="!nodeType">点击地图开始绘制 双击结束绘制</span>
        <el-button @click="onModalChange(false)">取 消</el-button>
        <el-button @click="submitClick"
          type="primary">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import AreaTree from '@/components/AreaTree';
import { addRegion, editRegion } from '@/api/getRule.js';
import GPS from '@/utils/address';


export default {
  name: 'AddClass',
  components: {
    AreaTree
  },

  model: {
    prop: 'modal',
    event: 'modal-change'
  },

  props: {
    modal: {
      type: Boolean,
      default: false
    },
    nodeType: {// 1：添加区域分类 0：添加区域
      type: Number,
      default: 1
    },

    iProp: { // 传入修改数据
      type: Object,
      default() {
        return {};
      }
    },
    editType: {//编辑类型  type:0, 添加 2：修改
      type: Number,
      default: 0,
    },
    beforeCommit: { // 提交前的操作
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      title: '编辑分类',
      conditionObj: this.initParams(), // 添加区域分类参数
      tabs: this.initTabsList(),
      type: 0, // 用于显示当前的画笔类型
      conditionObjs: {}
    };
  },
  watch: {
    modal: {
      // immediate: true,
      async handler(val) {
        //打开操作
        this.conditionObj = this.initParams();
        if (val) {
          this.title = ['编辑区域', '编辑分类'][this.nodeType];

          this.type = 0;

          if (this.iProp.parentId && !this.editType) { // 树节点添加默认在
            this.conditionObj.parentId = this.iProp.pathId;
          }

          if ([2].includes(this.editType))

            return [this.conditionObj = this.iProp, this.conditionObjs = Object.assign({}, this.iProp)];
        }
      }
    },
  },
  methods: {
    initParams() {
      return {
        pathName: "", //区域名称
        pathType: 30, //区域类型 30:区域分类 41：圆形区域,42：线形区域,43：矩形区域,44：多边形区域
        parentId: -1, //父id
        nodeType: 1,
        pointList: [], // 绘制区域的点集合
      };
    },
    onModalChange(modal, eventName = 'on-cancel', ...options) {
      this.$emit('modal-change', !!modal);
      this.$emit(eventName, ...options);
    },
    // 加载画笔类型
    initTabsList() {
      return [
        { label: '圆形', type: 41 },
        { label: '线形', type: 42 },
        { label: '矩形', type: 43 },
        { label: '多边形', type: 44 },
      ];
    },
    //树节点点击事件
    nodeClick(item) {
      const { node } = item;
      this.conditionObj.parentId = node.pathId || -1;
    },

    // 绘制类型点击事件
    drawTypeClick(data) {
      this.type = null;
      this.type = data.type;
      this.conditionObj.pathType = data.type;
      this.$emit('on-select-type', data);
    },

    // 圆形区域的数据
    setCircle(points) {
      this.conditionObj.pointList = [];
      const { radius, center } = points;

      let params = {
        oriLon: center.lng,
        oriLat: center.lat,
        radius: radius
      };

      this.conditionObj.pointList.push(params);
    },

    //线性/多边形区域数据
    setPoints(points) {
      this.conditionObj.pointList = [];
      let path = [];
      if (points.path) {
        path = points.path;
      } else {
        path = points;
      }
      this.conditionObj.pointList = path.map(point => ({ oriLon: point.lng, oriLat: point.lat }));
    },

    // 提交数据点击事件
    async submitClick() {
      this.conditionObjs.pathName = this.$refs.inputData.value;
      this.conditionObj.pathName = this.$refs.inputData.value;
      if (this.editType === 2) {
        this.beforeCommit && (await this.beforeCommit());
      }

      const { pathName, pointList } = this.conditionObj;

      if (pathName === '') return this.$message.warning("请输入区域名称");
      let result = {};
      if (this.nodeType === 0 && !pointList.length) return this.$message.warning("请绘制区域");

      this.conditionObj.pointList = pointList.map(item => {
        const point = GPS.deltaOut(item.oriLat, item.oriLon);
        return {
          ...item,
          oriLat: point.lat,
          oriLon: point.lon
        };
      });
      switch (this.editType) {
        case 0: //添加区域分类
          result = await addRegion({ ...this.conditionObj, nodeType: this.nodeType, });
          if (result.flag !== 1) return this.$message.error(result.msg);

          break;

        case 2:  //修改区域分类
          result = await editRegion({ ...this.conditionObj, nodeType: this.nodeType, });
          if (result.flag !== 1) return this.$message.error(result.msg);

          break;
      }
      this.onModalChange(false);
      this.$emit('on-ok', result.obj);
    },

  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: 10px;
  background: #212d3e;
  color: #fff;
  font-size: 14px;
  .el-dialog__headerbtn {
    top: 10px;
    i {
      color: #fff;
    }
  }
}
.footer-span {
  color: #dd2421;
  font-size: 12px;
  margin-right: 25px;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__footer {
  padding: 10px;
  background: #f3f3f3;
}
.addClass {
  height: 350px;
  &__content {
    height: 87%;
  }
  &__row {
    line-height: 40px;
    padding: 5px 10px 0 0;
    box-sizing: border-box;
    background: #f3f3f3;
    .center {
      text-align: center;
    }
    .draw-pane {
      padding: 0 0px 0 13px;
      .el-button {
        width: 25%;
      }
      .active {
        background: #ffd8bb;
        color: #000000;
      }
    }
  }
}
</style>