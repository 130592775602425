<template>
  <div class="electronicFence" style="display:flex">
    <Split direction="row" :sideOffset="0.18">
      <template #first>
        <el-collapse v-model="activeName">
          <el-collapse-item title="区域分类" name="1">
            <el-tabs :stretch="true">
              <el-tab-pane label="区域">
                <div class="modal-main">
                  <AreaTree
                    ref="AreaTree"
                    :isHover="true"
                    @handleOperate="handleClick"
                    @on-change="nodeSelect"
                  />
                </div>
              </el-tab-pane>
              <el-tab-pane label="省市">
                <div class="modal-main">
                  <AreaTree
                    ref="CityTree"
                    :getCity="true"
                    @on-change="selectArea"
                  />
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-collapse-item>
        </el-collapse>
        <!-- 添加/修改弹框  -->
        <AddClass
          v-model="visible"
          ref="AddClassRefs"
          :nodeType="nodeType"
          :editType="editType"
          :iProp="editParams"
          :beforeCommit="onBeforeCommit"
          @on-ok="onSuccess"
          @on-select-type="selectClick"
          @modal-change="channelModal"
        />
        <div class="btns">
          <el-button
            slot="reference"
            v-for="(item,index) in tabs"
            :key="index"
            :type="item.type"
            :icon="item.icon"
            size="mini"
            @click="handleOperate(index)"
            v-show="item.checked"
          >{{item.name}}</el-button>
        </div>
      </template>
      <template #second>
        <AMap ref="mapRef" @loaded="drawType" :zoom="8">
          <!-- 圆形 -->
          <MapCircle
            ref="circleRef"
            v-if="pathType === 41"
            :isVisible="pathType==41"
            :center="circleProps.center"
            :radius="circleProps.radius"
            editable
            :editIsOpen="circleProps.editIsOpen"
            autoFitView
          ></MapCircle>

          <!-- 折线 -->
          <MapPolyline
            ref="polylineRef"
            v-if="pathType === 42"
            :isVisible="pathType==42"
            :path="polylineProps.path"
            editable
            :editIsOpen="polylineProps.editIsOpen"
            autoFitView
          ></MapPolyline>

          <!-- 矩形 -->
          <MapRectangle
            ref="rectangleRef"
            v-if="pathType === 43"
            :isVisible="pathType==43"
            :bounds="rectangleProps.bounds"
            editable
            :editIsOpen="rectangleProps.editIsOpen"
            autoFitView
          ></MapRectangle>

          <!-- 多边形 -->
          <MapPolygon
            ref="polygonRef"
            v-if="pathType === 44"
            :isVisible="pathType==44"
            :path="polygonProps.path"
            editable
            :editIsOpen="polygonProps.editIsOpen"
            autoFitView
          ></MapPolygon>

          <template v-if="pathType===100">
            <MapPolygon
              v-for="(item,index) in cityList"
              :key="index"
              :path="item"
              editable
              autoFiView
            ></MapPolygon>
          </template>
        </AMap>
      </template>
    </Split>
  </div>
</template>

<script>
import AreaTree from '@/components/AreaTree';
import AddClass from "./components/AddClass.vue";
import Split from '@/components/Split';
import AMap, { MapCircle, MapPolyline, MapPolygon, MapRectangle } from '@/components/AMap';

import { deleteRegion } from '@/api/getRule.js';
import { MapMouseTool } from '@/components/AMap/utils';

import { districtSearch } from '@/components/AMap/utils/districtSearch';
import GPS from '@/utils/address';

export default {
  // `name`与路由保持一致
  name: 'ElectronicFence',
  components: {
    Split,
    AMap,
    AddClass,
    AreaTree,
    MapCircle,
    MapPolyline,
    MapPolygon,
    MapRectangle
  },
  data() {
    this.mouseTool = null; // 创建鼠标工具
    return {
      activeName: '1', // 折叠面板 active
      nodeType: 1, // 1：添加区域分类 0：添加区域
      editType: 0, // 编辑类型
      editParams: {}, // 修改数据
      tabs: [
        {
          name: '分类',
          icon: 'el-icon-plus',
          type: 'primary',
          checked: true
        },
        {
          name: '区域',
          icon: 'el-icon-plus',
          type: 'primary',
          checked: true
        },
      ],
      pathType: 30,
      edit: false, // 是否开启编辑状态
      visible: false, // addClass 属性
      circleProps: { // 圆形区域数据
        center: [],
        radius: 0,
        editIsOpen: false,
      },
      polylineProps: { // 线形区域数据
        path: [],
        editIsOpen: false,
      },

      rectangleProps: { // 矩形区域数据
        bounds: [],
        editIsOpen: false,
      },

      polygonProps: {// 多边形区域数据
        path: [],
        editIsOpen: false,
      },

      cityList: [],// 省市区绘制参数
    };
  },
  methods: {
    // 选择省市区
    async selectArea({ node }) {
      this.pathType = 100;
      // 查询省市区
      const result = await districtSearch(node.code, node.grade);

      //高德返回的数据无需纠偏
      this.cityList = result.map(item => {
        return item.map(subItem => {
          return [subItem.lng, subItem.lat];
        });
      });
    },

    // 修改区域
    async onBeforeCommit() {
      const { pathType } = this.editParams;
      let params = {
        radius: 0,
        center: {},
        path: []
      };

      if (pathType === 41) {
        const { AddClassRefs } = this.$refs;
        params.radius = this.$refs.circleRef.getRadius();
        params.center = this.$refs.circleRef.getCenter();
        AddClassRefs.setCircle(params);
        this.circleProps.editIsOpen = false;
      }

      if (pathType === 42) {
        params.path = this.$refs.polylineRef.getPath();
        this.$refs.AddClassRefs.setPoints(params);
        this.polylineProps.editIsOpen = false;
      }

      if (pathType === 43) {
        let bounds = this.$refs.rectangleRef.getBounds();
        params.path = this.getCoordinateooint(bounds);
        this.$refs.AddClassRefs.setPoints(params);
        this.rectangleProps.editIsOpen = false;
      }
      if (pathType === 44) {
        params.path = this.$refs.polygonRef.getPath();
        this.$refs.AddClassRefs.setPoints(params);
        this.polygonProps.editIsOpen = false;
      }
    },


    //添加事件
    handleOperate(params) {
      this.visible = true;
      this.editParams = {};
      switch (params) {
        case 0: // 添加区域分类事件
          this.nodeType = 1;
          this.editClick({}, params);
          break;

        case 1: // 绘制区域事件
          this.nodeType = 0;
          this.pathType = 0;
          this.initMap();
          break;
        default:
      }
    },

    // 初始化地图
    initMap() {
      this.circleProps.center = [];
      this.polylineProps.path = [];
      this.rectangleProps.bounds = [];
      this.polygonProps.path = [];
    },

    // 树节点点击事件
    nodeSelect(data) {
      const { node } = data;

      if (node.pathType === 30) return;

      if (!node.pointList.length) return this.$message.error("该数据没有区域");

      this.drawAreaChange(node, false);
    },

    // 树功能点击事件
    handleClick(item) {
      this.nodeType = 1;
      let { type, data } = item;
      switch (type) {
        case 0: // 添加事件
          this.visible = true;

          this.editClick(data, type);
          break;

        case 1: // 删除事件
          this.deleteClick(data);
          break;

        case 2: // 编辑事件
          this.visible = true;
          this.drawAreaChange(data, true);
          this.editClick(data, type);

          break;
        default:
      }
    },

    // 显示区域
    drawAreaChange(node, edit) {
      this.pathType = node.pathType;
      let pointList = node.pointList.map(item => {
        const point = GPS.delta(item.oriLat, item.oriLon);
        return {
          ...item,
          oriLat: point.lat,
          oriLon: point.lon
        };
      });

      switch (node.pathType) {
        case 41:
          const params = pointList[0]; // 绘制区域
          this.circleProps.center = [params.oriLon, params.oriLat];
          this.circleProps.radius = params.radius;
          this.circleProps.editIsOpen = edit;

          break;
        case 42:
          this.polylineProps.path = pointList.map(point => ([point.oriLon, point.oriLat]));
          this.polylineProps.editIsOpen = edit;

          break;
        case 43:
          this.rectangleProps.bounds = pointList.map(point => ([point.oriLon, point.oriLat]));
          this.rectangleProps.editIsOpen = edit;

          break;
        case 44:
          this.polygonProps.path = pointList.map(point => ([point.oriLon, point.oriLat]));
          this.polygonProps.editIsOpen = edit;

          break;
        default:
      }
    },

    // 类型点击事件
    selectClick(data) {
      this.initMap();
      this.drawType(data);
    },

    // 关闭modal事件
    channelModal() {
      // 关闭地图编辑状态
      this.polygonProps.editIsOpen = false;
      this.rectangleProps.editIsOpen = false;
      this.polylineProps.editIsOpen = false;
      this.circleProps.editIsOpen = false;

      this.pathType = 0;
    },

    // 绘制区域事件
    async drawType(data = {}) {

      if (this.mouseTool) this.mouseTool.close();

      const { type } = data;
      if (!type) return;

      this.Mamp = this.$refs.mapRef.getMap(); // 获取地图实例
      this.mouseTool = new MapMouseTool(this.Mamp); // 创建鼠标工具
      this.pathType = type;

      const { AddClassRefs } = this.$refs;

      let result = null;
      let path = [];

      switch (type) {
        case 41: // 绘制圆形区域 
          result = await this.mouseTool.drawCircle();
          AddClassRefs.setCircle(result);
          const { radius, center } = result; // 绘制区域
          this.circleProps.center = [center.lng, center.lat];
          this.circleProps.radius = radius;
          // this.circleProps.editIsOpen = true;

          break;
        case 42:// 绘制线形区域
          result = await this.mouseTool.drawPolyline();
          AddClassRefs.setPoints(result);

          path = result.path; // 绘制区域
          this.polylineProps.path = path.map(point => ([point.lng, point.lat]));
          // this.polylineProps.editIsOpen = true;

          break;
        case 43:// 绘制矩形区域
          result = await this.mouseTool.drawRectangle();

          const { bounds } = result;

          path = this.getCoordinateooint(bounds);
          AddClassRefs.setPoints(path);

          this.rectangleProps.bounds = path.map(point => ([point.lng, point.lat]));
          // this.rectangleProps.editIsOpen = true;

          break;
        case 44:// 绘制多边形区域
          result = await this.mouseTool.drawPolygon();
          AddClassRefs.setPoints(result);

          path = result.path; // 绘制区域
          this.polygonProps.path = path.map(point => ([point.lng, point.lat]));
          // this.polygonProps.editIsOpen = true;

          break;
        default:
      }
    },

    // 获取坐标点
    getCoordinateooint(bounds) {
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();

      let points = [
        { lng: southwest.getLng(), lat: southwest.getLat() },
        { lng: northeast.getLng(), lat: northeast.getLat() },
      ];

      return points;
    },

    // 添加成功后调用
    onSuccess(row) {
      // 关闭编辑状态
      this.circleProps.editIsOpen = false;
      this.polylineProps.editIsOpen = false;
      this.rectangleProps.editIsOpen = false;
      this.polygonProps.editIsOpen = false;
      this.visible = false;
      this.pathType = 0;
      this.editType = 0;
      const { AreaTree } = this.$refs;
      AreaTree.initTreeData();
    },

    // 添加/修改 点击事件 type:0, 添加 2：修改
    editClick(item, type) {
      this.editType = type;
      this.editParams = item;
    },

    // 删除点击事件
    deleteClick(item) {
      this.$confirm(`是否确认删除 ${item.pathName}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const result = await deleteRegion({ pathId: item.pathId });

        if (result.flag !== 1) return this.$message.error(result.msg);

        this.$message.success(result.msg);
        const { AreaTree } = this.$refs;
        AreaTree.initTreeData();

      });
    },

  }
}
</script>
<style lang="scss" scoped>
.electronicFence {
  position: relative;
  height: 100%;
  background: #eee;
  width: 100%;
  .modal-main {
    height: 100%;
    overflow: auto;
  }
  .btns {
    height: 5%;
    background: #ffffff;
    margin: 10px 0 0 0;
    padding: 6px;
    text-align: center;
  }
}

::v-deep .el-collapse {
  border: none;
  height: 94%;
  background: #ffffff;
  .el-collapse-item {
    height: 100%;
    .el-collapse-item__header {
      font-size: 16px;
      font-weight: 700;
      padding: 0 10px;
    }
    .el-collapse-item__wrap {
      height: 94%;
      .el-collapse-item__content {
        height: 100%;
        padding-bottom: 10px;
      }
    }
  }
}
::v-deep .el-tabs {
  width: 100%;
  height: 100%;
  .el-tabs__header {
    margin: 0;
    background-color: #dfdfdf;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    .is-active {
      color: #000000 !important;
      background-color: #1890ff !important;
      border: none !important;
    }
  }
  .el-tabs__content {
    padding: 0;
    height: 95%;
    .el-tab-pane {
      height: 100%;
    }
  }
}
::v-deep .areaTree__search {
  background: #ffffff;
}
::v-deep .el-input__inner {
  background: #ffffff !important;
}
::v-deep .v-modal {
  width: 20%;
}
::v-deep .el-dialog__wrapper {
  right: inherit;
  left: 15px;
}
</style>